import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import Tile from '../components/Tile'

const Datenschutz = ({data}) => (
            <Layout>
                <div id="main">
                    <div className="content">
                        <h1>{data.privacyPolicy.headline}</h1>
                        <div>
                            {data.privacyPolicy.body}
                        </div>
                    </div>
               </div>

            </Layout>
)

export const query = graphql`
{
    title: allSanitySettings(filter: {key: {eq: "Title"}}) {
        edges {
        node {
            key
            value
        }
        }
    }
    phone:  allSanitySettings(filter: {key: {eq: "Phone"}}) {
        edges {
        node {
            key
            value
        }
        }
    }
    email: allSanitySettings(filter: {key: {eq: "Email"}}) {
        edges {
        node {
            key
            value
        }
        }
    }
    privacyPolicy: sanityPage(headline: {eq: "Datenschutzerklärung"}) {
        headline
        body
    } 
}
`

export default Datenschutz
